import {useEffect} from "react";
import {throttle} from "throttle-debounce";

export function useParallax(mousemoveAreaRef,parRefEnum)
{

    useEffect(() => {
        const parallax = throttle(20, (event) => {
            let mouseX_ = event.clientX;
            let mouseY_ = event.clientY;
            for (let parRef of parRefEnum) {
                const depth = parRef[1]
                const translate3d = `translate3d(${-mouseX_ * depth / 10}px, ${-mouseY_ * depth / 10}px, 0)`
                parRef[0].current.style['-webkit-transform'] = translate3d
                parRef[0].current.style['-moz-transform'] = translate3d
                parRef[0].current.style['-ms-transform'] = translate3d
                parRef[0].current.style['-o-transform'] = translate3d
                parRef[0].current.style.transform = translate3d
            }

        })
        mousemoveAreaRef.current.addEventListener('mousemove', parallax)
        return () => {mousemoveAreaRef.current.removeEventListener('mousemove',parallax)}
    }, [mousemoveAreaRef])


}